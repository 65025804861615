import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Impressum = () => {
  return (
    <div>
      <Layout>
        <Seo title="Impressum" />
        <div className="container mx-auto">
          <br />
          <br />
          <h1>
            <strong> Impressum: „Zweitblick“-Lektorat</strong>
          </h1>
          <br />
          <br />
          <div>
            <p>Lea Gremm</p>
            <p>Haymerlegasse 35</p>
            <p>1160 Wien</p>
            <p>Österreich</p>
          </div>
          <br />
          <br />
          <div>
            <p>
              <b>Unternehmensgegenstand:</b> Lektorats- und Korrekturleistungen
            </p>
            <p>
              <b>Unternehmensform:</b> Kleinunternehmen gemäß § 6 Abs. 1 Nr. 27
              UStG
            </p>
          </div>
          <br />
          <p>
            <b>E-Mail:</b> lea-gremm@zweitblick-lektorat.at
          </p>
          <br />
          <br />
          <br />
          <h3 className="font-bold">Urheberrechtshinweis</h3>
          <br />

          <p>
            Die Inhalte dieser Webseite unterliegen dem Urheberrecht.
            Unerlaubtes Verbreiten oder Vervielfältigen von Inhalten dieser
            Webseite wird rechtlich verfolgt. Bildernachweis Alle Bilder, Fotos
            und Grafiken dieser Webseite sind urheberrechtlich geschützt. Die
            Bilderrechte liegen bei: Anna Sommerfeld Photography
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </Layout>
    </div>
  )
}

export default Impressum
